import React from 'react'
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Footer from '../components/Footer';
import Header from '../components/Header';
import useSiteMetadata from './SiteMetadata';

import '../sass/styles.scss';

const TemplateWrapper = ({ footerData = null, headerData = null, children }) => {
  const { title, description } = useSiteMetadata()
  
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        {/* <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/img/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-32x32.png"
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href="/img/favicon-16x16.png"
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href="/img/safari-pinned-tab.svg"
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" /> */}
      </Helmet>
      
      <div className="page">
        <Header data={headerData} />

        <main role="main">
          {children}
        </main>
       
        <Footer data={footerData}/>
      </div>
    </>
  )
}

export const query = graphql`
  fragment LayoutFragment on Query {
    footerData: markdownRemark(frontmatter: { templateKey: { eq: "footer" } }) {
      id
      frontmatter {
        heading
        telephone
        email
        vcard {
          publicURL
        }
      }
    }
    headerData: markdownRemark(frontmatter: { templateKey: { eq: "header" } }) {
      id
      frontmatter {
        twitter
        linkedin
      }
    }
  }
`;


export default TemplateWrapper
