import React from 'react';
import PropTypes from 'prop-types';

const Dropdown = ({id, label, children}) => {
    const toggleRef = React.useRef(null);
    const dropdownRef = React.useRef(null);
    const [isOpen, setOpen] = React.useState(false);

    function handleDocumentClick( e ){
        if( !dropdownRef.current ) return;

        const isOutsideClick = !toggleRef.current.contains( e.target ) && !dropdownRef.current.contains( e.target );

        if ( isOutsideClick && isOpen ) {
            setOpen( false )
        }
    }

    React.useEffect(() => {
        document.addEventListener('mouseup', handleDocumentClick );

        return () => {
            document.removeEventListener('mouseup', handleDocumentClick );
        }
    });

    return (
        <>
            <button className={`dropdown-toggle ${isOpen ? 'is-open': ''}`} type="button" aria-controls={id} onClick={e => setOpen(!isOpen)} ref={toggleRef}>{label}</button>
            <div id={id} className="dropdown-menu" style={{ display: isOpen ? 'block': 'none'}} ref={dropdownRef}>
                { children }
            </div>
        </>
    )
}

Dropdown.propTypes = {
    
};

export default Dropdown;