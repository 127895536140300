import React from 'react';
import { Link as GatsbyLink, navigate } from "gatsby";
import { Link as SmoothLink, animateScroll } from "react-scroll";

const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
    const isInternal = /^\/(?!\/)/.test(to);
    const isHash = to.startsWith('#');
    
    function handleClick(to, e){
        e.preventDefault();

        if( !to.startsWith('/#') ) {
            navigate( to );
        } else  {
            const pathname = to.split('#')[0];

            if ( window.location.pathname === pathname ) {
                const target = document.getElementById(to.slice(2));

                if( target ) {
                    const offset = target.getBoundingClientRect().top;
                    animateScroll.scrollMore(offset);
                }
            } else {
                navigate( to );
            }
        }        
    }

    // Use Gatsby Link for internal links, and <a> for others
    if (isInternal || isHash) {
      return isHash ? (
        <SmoothLink to={to.slice(1)} smooth={true} {...other}>
            {children}
        </SmoothLink>
      ) : ( 
        <GatsbyLink
          to={to}
          onClick={handleClick.bind(null, to)}
          {...other}
        >
          {children}
        </GatsbyLink>
      )
    }
    return (
      <a href={to} {...other}>
        {children}
      </a>
    )
  }
  
  export default Link