import React, { useState } from 'react';
import { graphql, StaticQuery } from "gatsby";
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';
import Link from './Link';

const Header = ({ data }) => {
    const { twitter, linkedin } = data.frontmatter;
    const [ isNavOpen, setNavOpen ] = useState( false );
    
    function handleNavClick( e ) {
        setNavOpen( !isNavOpen )
    }

    return (
        <header className="navbar" role="banner">
            <h1 className="brand">
                <Link to="/"><strong>Missions</strong> that <strong>Matter</strong></Link>
            </h1>

            <div id="menu" className={['navigation', isNavOpen ? 'is-open' : ''  ].join(' ')} >
                <nav>

                    <StaticQuery
                        query={graphql`
                            query {
                                work: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "case-study"}}}, sort: { order:  ASC, fields: [ frontmatter___date ]}) {
                                    edges {
                                      node {
                                        id
                                        fields {
                                          slug
                                        }
                                        frontmatter {
                                          label
                                        }
                                      }
                                    }
                                }
                                services: allMarkdownRemark(filter: {frontmatter: {templateKey: {eq: "service"}}}, sort: { order:  DESC, fields: [ frontmatter___title ]}) {
                                    edges {
                                      node {
                                        id
                                        fields {
                                          slug
                                        }
                                        frontmatter {
                                          label
                                        }
                                      }
                                    }
                                }
                            }
                        `}
                        render={ data => {
                            const { work, services } = data;

                            return (
                                <ul className="navigation__primary nav">
                                    <li>
                                        <Link to="/#intro">What I Do</Link>
                                    </li>
                                    <li>
                                        <Dropdown label="Work" id="dropdown-work">
                                            <ul className="navigation-subnav">
                                                { work.edges.map( item => 
                                                    <li key={item.node.id}>
                                                        <Link to={item.node.fields.slug}>{item.node.frontmatter.label}</Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </Dropdown>
                                    </li>
                                    <li>
                                        <Dropdown label="Services" id="dropdown-services">
                                            <ul className="navigation-subnav">
                                                { services.edges.map( item => 
                                                    <li key={item.node.id}>
                                                        <Link to={item.node.fields.slug}>{item.node.frontmatter.label}</Link>
                                                    </li>
                                                )}
                                            </ul>
                                        </Dropdown>
                                    </li>
                                    <li>
                                        <Link to="/about/">About</Link>
                                    </li>
                                    <li>
                                        <Link to="#contact">Contact</Link>
                                    </li>
                                </ul>
                            )
                        }}/>
                    
                    <ul className="navigation__social">
                        <li><a href={twitter} className="icon-twitter" target="_blank" rel="noopener noreferrer"><span className="is-visually-hidden">Twitter</span></a></li>
                        <li><a href={linkedin} className="icon-linkedin" target="_blank" rel="noopener noreferrer"><span className="is-visually-hidden">LinkedIn</span></a></li>
                    </ul>
                </nav>
            </div>

            <a id="menu-toggle" className="navbar__menu-toggle" href="#menu" role="button" aria-expanded={ isNavOpen } aria-controls="menu" onClick={ handleNavClick }>
                <span className="hamburger"></span>
                <span className="is-visually-hidden">Menu</span>
            </a>
        </header>
    )
};

Header.propTypes = {
    
};

export default Header;