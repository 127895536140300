import React from 'react';

const Footer = ({data}) => {
  const { heading, telephone, email, vcard } = data.frontmatter;

  return (
    <footer id="contact" className="footer">
        <div className="footer__inner">
            <div className="container">
                <h2>{heading}</h2>
                <div className="content-container">
                    <ul className="footer__contact">
                        <li><a href={`tel:${telephone.replace(/ /g, '').replace('(0)', '')}`}>{telephone}</a></li>
                        <li><a href={`mailto:${email}`}><span className="visible-xs">Email me</span><span className="is-visually-hidden">:</span> <span className="hidden-xs">{email}</span></a></li>
                    </ul>
                    <a href={vcard.publicURL} className="button button--primary-rev">Download vCard <span className="icon-download"></span></a>

                    <div className="footer__accred">
                        <img className="footer__accred-img" src="/img/accred-agility-360.png" alt="" width="120"/>
                        <span className="footer__accred-label">Certified Leadership Agility 360 coach</span>
                    </div>
                </div>
            </div>
        </div>
        <small className="footer__smallprint">&copy; Copyright {new Date().getFullYear()} Missons That Matter. All rights reserved</small>
    </footer>
  )
}

export default Footer
